import styled from 'styled-components'
import type { ColorKeys } from '../styles/colors'

function HODLogo( { color = null }: { color?: ColorKeys } ) {
  const c = color || 'grey8'

  return (
    <StyledHODLogo $color={ c }>
      <svg 
        width="112"
        height="113"
        viewBox="0 0 112 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          d="M16.5419 39.6853H39.873V16.3393H16.5419V39.6853ZM112 112.052V0H56.0066V56.0345H0V112.052H112Z"
        />
      </svg>
    </StyledHODLogo>
  )
}

const StyledHODLogo = styled.div<{ $color: ColorKeys }>`
  svg path {
    fill: ${ p => p.theme.getColor( p.$color ) };
    transition: fill .3s linear;
  }
`

export default HODLogo
