import { useState } from 'react'
import styled, { css } from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { useAuth } from '../lib/auth'
import usePreventBodyScroll from '../hooks/usePreventBodyScroll'
import Link from './Link'
import HODLogo from './HODLogo'
import BurgerIcon from './icons/BurgerIcon'
import Container from './layout/Container'
import Button from './Button'

function Nav( { buttonColor } ) {
  const me = useAuth()
  const [isOpen, setIsOpen] = useState( false )
  usePreventBodyScroll( isOpen, 'nav' )

  const logOut = () => {
    me.logout().then( () => {
      setIsOpen( false )
    } )
  }

  return (
    <StyledNav $isOpen={ isOpen }>
      <StyledNavToggle>
        <button
          type="button"
          onClick={ () => setIsOpen( s => !s ) }
        >
          <BurgerIcon 
            color={ buttonColor } 
            isOpen={ isOpen }
          />
          <span>
            <FormattedMessage
              description="Navigation: menu"
              defaultMessage="Menu"
              id="DFs3Ls"
            />
          </span>
        </button>
      </StyledNavToggle>
      <StyledNavPanel>
        <Container>
          <div>
            <div>
              <StyledNavLogo>
                <Link href="/">
                  <HODLogo color="grey9" />
                </Link>
              </StyledNavLogo>
            </div>
            <StyledNavList>
              <div>
                <StyledNavListItem $large>
                  <Link href="https://ifatreefellinaforest.housesofdarkness.eu/">
                    If a Tree Fell in a Forest
                  </Link>
                </StyledNavListItem>
                <StyledNavListItem $large>
                  <Link href="https://falstad.housesofdarkness.eu">
                    Perpetrator Perspectives
                  </Link>
                </StyledNavListItem>
                <StyledNavListItem $large>
                  <Link href="/westerbork">
                    His Name is My Name
                  </Link>
                </StyledNavListItem>
              </div>
              <StyledNavListItem>
                <Button
                  $rounded
                  $flat
                  $color="highlight"
                >
                  <Link href="/lets-discuss">
                    <FormattedMessage
                      description="Navigation: Let’s discuss"
                      defaultMessage="Let’s discuss"
                      id="I5Ql+9"
                    />
                  </Link>
                </Button>
                <Link href="/lets-explore">
                  <span>
                    <FormattedMessage
                      description="Navigation: Information page"
                      defaultMessage="More information"
                      id="IKRuft"
                    />
                  </span>
                </Link>
              </StyledNavListItem>
              <div>

                <StyledNavListItem>
                  <Link href="/about">
                    <FormattedMessage
                      description="Navigation: About the project"
                      defaultMessage="About the project"
                      id="/aY6fS"
                    />
                  </Link>
                </StyledNavListItem>
                <StyledNavListItem>
                  <Link href="/contact">
                    <FormattedMessage
                      description="Navigation: Contact"
                      defaultMessage="Contact"
                      id="JfME6e"
                    />
                  </Link>
                </StyledNavListItem>
              </div>
              { me?.isAuthenticated && (
                <div>
                  <StyledNavListItem 
                    $separate
                    $small
                  >
                    <Link href="/dashboard">
                      <FormattedMessage
                        description="Navigation: My Creative Response"
                        defaultMessage="My Creative Response"
                        id="4ygyoy"
                      />
                    </Link>
                  </StyledNavListItem>
                  { me.user.role === 'HOD_ADMIN' && (
                    <StyledNavListItem 
                      $small
                    >
                      <Link href="/moderate">
                        <FormattedMessage
                          description="Navigation: Moderate"
                          defaultMessage="Moderate"
                          id="ywS6Eu"
                        />
                      </Link>
                    </StyledNavListItem>
                  ) }
                  <StyledNavListItem $small>
                    <button
                      type="button"
                      onClick={ logOut }
                    >
                      <FormattedMessage
                        description="Log out"
                        defaultMessage="Log out"
                        id="8X/BlF"
                      />
                    </button>
                  </StyledNavListItem>
                </div>
              ) }
              { !me?.isAuthenticated && (
                <StyledNavListItem
                  $separate
                  $small
                >
                  <Link href="/login">
                    <FormattedMessage
                      description="Log in"
                      defaultMessage="Log in"
                      id="7sj5gn"
                    />
                  </Link>
                </StyledNavListItem>
              ) }
            </StyledNavList>
          </div>
        </Container>
      </StyledNavPanel>
    </StyledNav>
  )
}

interface WithIsOpen {
  $isOpen: boolean,
}

const StyledNavPanel = styled.div`
  position: fixed;
  overflow-y: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  background: linear-gradient( 
    180deg, 
    ${ p => p.theme.colors.grey2 } 11%, 
    ${ p => p.theme.colors.grey3 } 53%, 
    ${ p => p.theme.colors.grey5 } 100%
  );
  transition: opacity .3s ease-out;

  > div > div {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;

    > * {
      &:first-child {
        position: relative;
        flex-grow: 0;
        margin-top: 2rem;
      }

      &:last-child {
        flex-grow: 1;
      }
    }
  }
`

const StyledNav = styled.div<WithIsOpen>`
  display: flex;
  justify-content: flex-end;

  ${ p => p.$isOpen && css`
    ${ StyledNavPanel } {
      opacity: 1;
      pointer-events: all;
    }
  ` }
`

const StyledNavToggle = styled.div`
  z-index: 1;

  button {
    /* margin-right: -6px; */
    margin-top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background: transparent;
    border: none;

    span {
      display: block;
      position: absolute;
      left: -1000rem;
    }

    svg {
      display: block;
    }
  }
`

const StyledNavLogo = styled.div`
  a,
  svg {
    display: block;
    max-width: 100%;
  }

  @media ( max-width: 650px ) {
    width: 25vw;
  }
`

const StyledNavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  max-height: 800px;
  padding: 0 0 3rem 0;
  margin: 0;
  list-style: none;
`

interface StyledNavListItem {
  $large?: boolean,
  $small?: boolean,
  $separate?: boolean,
}

const StyledNavListItem = styled.li<StyledNavListItem>`
  text-align: center;

  button {
    padding: 0;
    border: 0;
    background: transparent;
  }

  a,
  button,
  span {
    text-decoration: none;
    color: ${ p => p.theme.colors.grey7 };
    font-size: ${ p => p.theme.typo.sizes.large };
    transition: color .3s ease-out;
  }

  a,
  button {
    color: ${ p => p.theme.colors.grey9 };
    cursor: pointer;

    &:hover {
      color: ${ p => p.theme.colors.highlight };
    }
  }

  ${ Button } {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    a {
      ${ p => p.theme.typo.neueHaasBold };
      font-size: ${ p => p.theme.typo.sizes.large };
      color: ${ p => p.theme.colors.grey9 };
      transition:
        background .3s ease-out,
        color .3s ease-out;

      &:hover {
        background: ${ p => p.theme.colors.grey9 };
        color: ${ p => p.theme.colors.highlight };
      }
    }
  }

  ${ Button } + a {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1.5rem;
    margin-bottom: 1rem;
    font-size: ${ p => p.theme.typo.sizes.normal };

    span {
      padding: 0.3rem 1rem;
      border: 1px solid transparent;
      border-radius: 100px;
      color: ${ p => p.theme.colors.grey9 };
      transition:
        color .3s ease-out,
        border-color .3s ease-out;
    }

    &:hover span {
      color: ${ p => p.theme.colors.white };
      border-color: ${ p => p.theme.colors.grey8 };
    }

    &:before {
      content: '';
      height: 1rem;
      border-left: 1px solid ${ p => p.theme.colors.grey8 };
    }
  }

  ${ p => p.$large && css`
    a,
    button,
    span {
      ${ p.theme.typo.neueHaas }
      font-size: ${ p.theme.typo.sizes.hero };
      line-height: 1.15;

      @media ( max-width: 650px ) {
        font-size: ${ p.theme.typo.sizes.smallHero };
      }
    }
  ` }
  
  ${ p => p.$small && css`
    a,
    button,
    span {
      font-size: ${ p.theme.typo.sizes.normal };
    }
  ` }
  
  ${ p => p.$separate && css`
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${ p.theme.colors.grey7 };
  ` }
`

export default Nav
